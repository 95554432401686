<script setup lang="ts">
import {
  PageFieldsFragment,
  MediaImageFragment,
  ToccoSeminarFieldsFragment,
  ToccoWebinarFieldsFragment,
  ToccoCourseFieldsFragment,
  ToccoModuleFieldsFragment,
  EventFieldsFragment,
  OpenJobFieldsFragment,
} from '~/api/graphql/generated'
import { Link } from '~/types'

const props = defineProps<{
  entity:
    | PageFieldsFragment
    | EventFieldsFragment
    | OpenJobFieldsFragment
    | ToccoSeminarFieldsFragment
    | ToccoWebinarFieldsFragment
    | ToccoCourseFieldsFragment
    | ToccoModuleFieldsFragment
  breadcrumbs?: Link[]
  type?: 'petrol' | 'bordeaux'
}>()

const { t } = useI18n()
const image = computed(() => {
  if (props.entity.__typename === 'ToccoSeminar') return
  if (props.entity.__typename === 'ToccoWebinar') return
  if (props.entity.__typename === 'ToccoCourse') return
  if (props.entity.__typename === 'ToccoModule') return

  return props.entity?.fieldMobileImage?.entity || props.entity?.fieldImage?.entity
    ? {
        small: props.entity?.fieldMobileImage?.entity as MediaImageFragment,
        large: props.entity?.fieldImage?.entity as MediaImageFragment,
      }
    : undefined
})

const subtitle = computed(() => {
  if (props.entity.__typename?.startsWith('Tocco')) {
    return t(`tocco.toccoTypes.${props.entity.__typename}`)
  }
  return props.entity.fieldSubtitle ?? ''
})

const intro = computed(() => {
  if (props.entity.__typename === 'ToccoSeminar') return
  if (props.entity.__typename === 'ToccoWebinar') return
  if (props.entity.__typename === 'ToccoCourse') return
  if (props.entity.__typename === 'ToccoModule') return

  return props.entity?.fieldLead?.processed || undefined
})
</script>

<template>
  <Hero
    :image="image"
    :breadcrumbs="breadcrumbs"
    :content="{
      title: entity.title ?? '',
      subtitle,
      intro,
    }"
  />
</template>
